@import "normalize"

// Variables

$sans-font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol
$serif-font-family: baskerville, serif
$mono-font-family: "american typewriter", courier, "courier new", monospace

$heading-font-family: $sans-font-family
$article-font-family: $sans-font-family

$background-color: #fff
$base-color: #222
$dark-color: #000
$medium-color: lighten($base-color, 10%)
$light-color: lighten($base-color, 50%)
$link-color: #0e55c8
$link-active-color: $dark-color
$link-hover-border-color: rgba($link-color, 0.1)
$link-active-border-color: rgba($link-active-color, 0.3)
$heading-color: #000

$base-font-weight: 400
$strong-font-weight: 500
$heading-font-weight: 500

$mobile-rem: 16px
$laptop-rem: 20px
$desktop-rem: 24px

$major-grid: 1.5rem
$minor-grid: $major-grid * 0.5
$indent: $major-grid * 1.5
$main-column-width: $major-grid * 24
$figure-width: $major-grid * 24
// $figure-width: $major-grid * 34.133333

$base-font-size: 1rem
$base-line-height: $major-grid

@mixin smaller-text
	font-size: 80%
	line-height: inherit

@mixin smaller-caps
	@include smaller-text
	text-transform: uppercase

// Desktop layout

html
	font-size: $desktop-rem
body
	margin: $major-grid 0
	padding: 0
	// Chrome needs explicit font-size on <body> for rem units to work
	font-size: $desktop-rem
	line-height: $base-line-height
	&>header>h1
		position: absolute
		left: $major-grid
		top: $major-grid * 3 + $minor-grid * 0.25

header,
footer,
main>article>section>*
	margin-left: auto
	margin-right: auto
	max-width: $main-column-width

main>article>section>figure
	max-width: $figure-width

// Laptop layout changes font and grid size

$laptop-max-width: 1.5 * $desktop-rem * 40

@media screen and (max-width: $laptop-max-width)
	html
		font-size: $laptop-rem
	body
		font-size: $laptop-rem
		line-height: $base-line-height

// Tablet layout changes font and grid size

$tablet-max-width: 1.5 * $laptop-rem * 32

@media screen and (max-width: $tablet-max-width)
	html
		font-size: $mobile-rem
	body
		font-size: $mobile-rem
		line-height: $base-line-height

// Somewhere between tablet and mobile, we move the logo to the top

$fixed-header-max-width: 1.5 * $mobile-rem * (24 + 8)

@media screen and (max-width: $fixed-header-max-width)
	a.fake-tag,
	body>header>h1
		position: relative
		left: 0
		top: 0

// Mobile layout transitions to fluid layout

$mobile-max-width: 1.5 * $mobile-rem * (24 + 2)

@media screen and (max-width: $mobile-max-width)
	header,
	footer,
	main>article>section>*
		max-width: 100vw
		margin-left: $major-grid
		margin-right: $major-grid

	main>article>section>figure
		margin-left: auto
		margin-right: auto

// Common styles

body
	background: $background-color
	color: $base-color
	font-family: $sans-font-family
	font-weight: $base-font-weight

a
	color: $link-color
	text-decoration: none
	&:hover
		color: $link-color
		border-bottom: 0.05em solid $link-hover-border-color
		&:active
			color: $link-active-color
			border-bottom-color: $link-active-border-color

strong, b
	font-weight: $strong-font-weight

p, pre, address, blockquote, fieldset, ul, ol, dl
	margin: 0 0 $major-grid 0
	font-size: inherit
	line-height: inherit

h1, h2, h3, h4, h5, h6
	margin: 0
	font-family: $heading-font-family
	font-weight: $heading-font-weight
	font-size: inherit
	line-height: inherit
	color: $heading-color
	a
		color: inherit
		&:hover
			color: $link-color
			&:active
				color: $link-active-color

h1, h2
	font-size: $base-font-size * 1.75
	line-height: $base-line-height * 1.5

pre, code
	font-family: $mono-font-family
	font-size: inherit
	line-height: inherit

ul, ol
	padding: 0
	&>li
		margin-left: $indent

img
	margin: 0
	border: 0
	vertical-align: bottom

figure
	display: block
	position: relative
	margin: 0 0 $major-grid 0
	padding: 0

small
	@include smaller-text

// Common layout

body>header
	// position: relative
	margin-bottom: $minor-grid
	&>h1
		display: block
		overflow: hidden
		width: $major-grid * 2.5
		height: $major-grid * 2.5
		margin: 0
		text-indent: -100em
		&>a
			display: block
			position: absolute
			left: 0
			top: 0
			width: 100%
			height: 100%
			background: url(/i/rr.svg) center center no-repeat
			background-size: 100%
			&:hover
				background-image: url(/i/rr.hover.svg)
				border-bottom: none
				&:active
					background-image: url(/i/rr.active.svg)

body>header,
body>footer
	@include smaller-text

	nav
		// @include smaller-caps

		ul
			overflow: hidden
			margin: 0 0 $major-grid * 2 0
			padding: 0
			li
				display: inline-block
				margin: 0 0.66em 0 0
				&.dotco
					clear: left
					@include smaller-caps

body>header
	a
		color: $light-color
		&:hover
			color: $link-color
			&:active
				color: $link-active-color

main
	margin-bottom: $major-grid * 2

	article
		margin-bottom: $major-grid * 4

		&>header
			position: relative
			& date
				display: block
				color: $light-color
				@include smaller-caps
				font-size: 65%

		&>footer
			@include smaller-text

		&>section
			margin: $minor-grid 0
			font-family: $article-font-family

		h3, h4, h5, h6
			margin-bottom: $major-grid
			color: $medium-color
			@include smaller-caps

		// figures display large images, video, iframes
		figure>div
			position: relative
			overflow: hidden
			height: 0
			&>iframe
				position: absolute
				left: 0
				top: 0
				width: 100%
				height: 100%
				background: transparent

		// full-bleed width
		figure.full
			width: 100%
			max-width: 100%
			text-align: center

		// 180px height for MixCloud player
		figure.mixcloud>div
			height: 180px

		// 16:9 aspect ratio
		figure.video>div
			padding-top: 56.25%

		// 4:3 ratio + 40px padding for controls
		figure.slideshare>div
			padding-top: 75%
			padding-bottom: 40px

		figure.square>div
			padding-top: 100%

		// gallery of images
		figure.gallery img
			margin-bottom: $minor-grid

		img, iframe, embed, object
			max-width: 100%
			height: auto
			&.full
				width: 100%

		blockquote
			font-style: italic
			&>*
				margin-left: $major-grid

		.left
			float: left
			margin: 0 $major-grid $major-grid 0

		.right
			float: right
			margin: 0 0 $major-grid $major-grid

		p.import
			@include smaller-text
			font-style: italic
			color: $light-color

		p.gallery
			max-width: $major-grid * 19
			img
				float: left
				margin: 0 $minor-grid $minor-grid 0

nav.pagination
	@include smaller-text

	a
		margin-right: 1em
		@include smaller-caps

// Debug

body.grid
	background-image: linear-gradient(#eee 0.05em, transparent 0.05em)
	background-size: 100% $major-grid
	background-repeat: repeat
	h1, h2, h3, h4, h5, h6, p, pre, address, blockquote, fieldset, ul, ol, dl
		background: rgba(0,0,0,0.0625)
